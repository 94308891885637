<template>
  <el-row class="top">
    <el-col :span="2">
            <label>
               <input type="checkbox" :checked="selectedAll" @change="modifySelected()"/><span>全选</span>
            </label>
    </el-col>
    <el-col :span="10" class="text-left shopInfo">
        商品信息
    </el-col>
    <el-col :span="3" class="text-center">单价</el-col>
    <el-col :span="3" class="text-center">数量</el-col>
    <el-col :span="3" class="text-center">小计</el-col>
    <el-col :span="3" class="text-center">操作</el-col>
  </el-row>
</template>

<script>
import {mapState,mapMutations} from "vuex";
export default {
  name:"CartHeader",
  computed: {
    ...mapState({
      selectedAll:state=>state.selectedAll
    })
  },
  methods: {
    ...mapMutations({
      modifySelected:"modifySelected"
    })
  },
  created(){
    // alert(this.selectedAll)

  }
};
</script>



<style lang="less" scoped>
  @import '~style/index.less';
 .top {
  width: 1200px;
  height: 50px;
  background: #F6F6F6;
  display: flex;
  align-items: center;
  color: #666666;
  font-size: 13px;
  line-height: 18px;
  label {
    padding-left: 10px;
    display: flex;
    align-items: center;
    input {
        background: red;
        cursor: pointer;
    }
    span {
      padding-left: 10px;
      cursor: pointer;
       &:hover{
                color: @theme;
            }
    }
  }
  .shopInfo {
    padding-left: 130px;
  }
}
 
</style>
